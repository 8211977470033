import React, { useState } from "react"
import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import Hero from "../components/home/hero"
import { HeroSection } from "../components/landingPage/HeroSection"
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { graphql } from "gatsby"
import CodeExample from "../components/home/CodeExample"
import { CompatibleLibraries } from "../components/landingPage/CompatibleLibraries"
import CLIOverview from "../components/CLIOverview"
import APIOverview from "../components/APIOverview"
import GridItem from "../components/GridItem"

const useStyles = makeStyles(theme => ({
  container: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: "30px",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "60px",
    },
  },
  h2: {
    textAlign: "center",
    color: theme.palette.primary.main,
    marginTop: "50px",
    marginBottom: "20px",
    fontSize: "1.75rem",
    fontWeight: "600",
  },
  text: {
    lineHeight: "2rem",
    fontSize: "1.3rem",
  },
  list: {
    listStyle: "circle",
  },
  logo: {
    [theme.breakpoints.up("md")]: {
      marginLeft: "50px",
    },
  },
  button: {
    marginTop: "15px",
    marginBottom: "30px",
  },
}))

const AngularLandingPage = ({ data, location }) => {
  const classes = useStyles()
  const [isFirstTranslated, setFirstTranslated] = useState(false)

  return (
    <Layout>
      <HeroSection>
        <Hero
          title="Do It Yourself Translation Management"
          subtitle="You can choose how you want to use Simpleen"
          callToAction="Start translating"
          source="Signup HeroAngular"
        />
      </HeroSection>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h2" className={classes.h2}>
              Translate Your i18n Locales Online
            </Typography>
          </Grid>
          <GridItem position="left" xs={12} md={6}>
            <Typography variant="body1" className={classes.text}>
              Machine translate i18n locales from a variety of libraries and
              technologies. Simply copy/paste your locales into the online
              translator and get instant translations.
              <br />
              <br />
              Localize your software for global markets at an early stage and
              reach a greater audience faster.
              <br />
              <br />
              Cost-effective translations of your multilingual projects.
            </Typography>
          </GridItem>
          <GridItem position="right" xs={12} md={6}>
            <CodeExample
              codeFirst={data.snippetExample}
              codeSecond={data.snippetExampleTranslated}
              langCodeFirst="en"
              langCodeSecond="ja"
              isTranslated={isFirstTranslated}
            />
            <Typography variant="body1" style={{ textAlign: "center" }}>
              <Button
                style={{ marginLeft: "auto", marginRight: "auto" }}
                variant="contained"
                color="primary"
                onClick={() => {
                  window &&
                    window.plausible &&
                    window.plausible(
                      isFirstTranslated
                        ? "Clicked Revert Example"
                        : "Clicked Translate Example"
                    )
                  setFirstTranslated(!isFirstTranslated)
                }}
              >
                {isFirstTranslated ? "Revert Example" : "Translate Example"}
              </Button>
            </Typography>
          </GridItem>
        </Grid>
      </Container>

      <Container maxWidth="lg" className={classes.container}>
        <CLIOverview
          fluidImage={data.CLIToolImage.childImageSharp.gatsbyImageData}
          title="Use CLI for Continuous Localization"
          callToAction="Signup"
          to="/signup"
          textSegments={[
            "Choose your technologies & project structures",
            "Get translations while still working on improvements and new features",
            "Speed up your localization workflow",
          ]}
        />
      </Container>
      <Container maxWidth="lg" className={classes.container}>
        <APIOverview
          fluidImage={data.APIToolImage.childImageSharp.gatsbyImageData}
          title="Build something"
          textSegments={[
            "Solve individual problems with the API",
            "Use complex data structures",
            "Ask us for custom implementations",
            // Get your api key
          ]}
          callToAction="Contact us"
          to="mailto:info@simpleen.io"
        />
      </Container>

      <CompatibleLibraries currentPage="i18nAngular" />
      {/*<LandingPageFeatures source="Signup ButtonAngular" />*/}
      <Seo
        general={{
          title: "Translate Locales during development yourself",
          description:
            "Machine Translation of locale files during i18n your software project",
          path: location.pathname,
          image: data.twitter.childImageSharp.gatsbyImageData.src,
        }}
      />
    </Layout>
  )
}

export const angularLPQuery = graphql`
  query diyLandingPageQuery {
    twitter: file(relativePath: { eq: "seo/i18nAngularSEO.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1000
          quality: 95
          placeholder: BLURRED
          layout: FIXED
        )
      }
    }
    angular: file(relativePath: { eq: "logos/i18nAngular.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 600
          quality: 95
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
    }
    CLIToolImage: file(relativePath: { eq: "home/CLIToolCommands.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    APIToolImage: file(relativePath: { eq: "apiFeature.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    snippetExample: markdownRemark(
      frontmatter: { slug: { eq: "/vueCodeLP3" } }
    ) {
      html
    }
    snippetExampleTranslated: markdownRemark(
      frontmatter: { slug: { eq: "/vueCodeLP3Translated" } }
    ) {
      html
    }
  }
`

export default AngularLandingPage

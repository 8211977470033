import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import { Link } from "gatsby"
import Grid from "@material-ui/core/Grid"
import { GatsbyImage } from "gatsby-plugin-image";
import GridItem from "./GridItem"

const useStyles = makeStyles(theme => ({
  h2: {
    color: theme.palette.primary.main,
    textAlign: "left",
    fontSize: "1.75rem",
    fontWeight: "600",
    [theme.breakpoints.up("xs")]: {
      marginTop: "40px",
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "50px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "20px",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "35px",
    },
  },
  paragraph: {
    lineHeight: "2rem",
    fontSize: "1.3rem",
  },
  paragraphButton: {
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      textAlign: "left",
    },
  },
  button: {
    marginTop: "1rem",
    [theme.breakpoints.up("xs")]: {
      marginBottom: "1rem",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
}))

const APIOverview = ({ fluidImage, title, textSegments, callToAction, to }) => {
  const classes = useStyles()
  return (
    <Grid container>
      <GridItem poisition="left" xs={12} md={6}>
        <Typography variant="h2" className={classes.h2}>
          {
            // API Tool to Automate i18n Translation
            // Instant Localization
            // Translate your projects fast.
            //               Continuous Localization (API)
            // Automatic multilingual software localization/translation
            // Multilingual software localization process
            // Fast and multilingual software localization process
          }
          {title}
        </Typography>
        {textSegments.map(text => (
          <p className={classes.paragraph}>{text}</p>
        ))}

        <p className={classes.paragraphButton}>
          {to.includes("mailto") && (
            <a href={to} style={{ textDecoration: "none" }}>
              <Button
                color="primary"
                variant="contained"
                className={classes.button}
              >
                {callToAction}
              </Button>
            </a>
          )}
          {!to.includes("mailto") && (
            <Link to={to} style={{ textDecoration: "none" }}>
              <Button
                color="primary"
                variant="contained"
                className={classes.button}
              >
                {callToAction}
              </Button>
            </Link>
          )}
        </p>
      </GridItem>
      <GridItem position="right" xs={12} md={6}>
        <GatsbyImage image={fluidImage} />
      </GridItem>
    </Grid>
  );
}

export default APIOverview
